import { createAction } from '@/utils/api'

import type { IRecruit, IMilestone, IAward, INews } from './types'
export type { IRecruit, IMilestone, IAward, INews } from './types'

/**
 * 人才招募
 */
export const loadRecruit = createAction<
  // Response
  {
    entries: IRecruit[]
    media_url: string
    attachment_url: string
  }
>('GET', '/api/:locale/about/recruit')


/**
* 首頁
*/
export const loadHome = createAction<
 // Response
 {
   sum_assets: string
   asset_date: string
   milestone: IMilestone[]
   media_url: string
   awards: []
 }
>('GET', '/api/:locale/about/home')


/**
* 獲獎殊榮
*/
export const loadAwards = createAction<
 // Response
 {
   awards: IAward[]
 }
>('GET', '/api/:locale/about/awards')


/**
* 跑馬燈
*/
export const loadNotify = createAction<
 // Response
 {
    entries: {
      content: string,
      url: string
    }[]
 }
>('GET', '/api/:locale/about/notify')


/**
* 每日新聞
*/
export const loadNews = createAction<
 // Response
 {
    news: INews[]
    date: string
 }
>('GET', '/api/:locale/about/news')